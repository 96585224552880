import jwt_decode from "jwt-decode";
import type { PermissionString } from "@/common/permissions";
import { defineStore } from "pinia";
import type { ServiceLanguageString } from "@/common/types";

export interface Token {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

export interface AccessToken {
  auth_time: number;
  exp: number;
  iat: number;
  username: string;
  staff_id: string;
  last_language: ServiceLanguageString;
  permitted: boolean;
  permissions: string[];
}

export interface AuthState {
  accessToken: string;
  refreshToken: string;
  expirationTime: number;
  username: string;
  staffId: string;
  lastLanguage: ServiceLanguageString | "";
  permitted: boolean;
  permissions: PermissionString[];
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => {
    return {
      accessToken: "",
      refreshToken: "",
      expirationTime: 0,
      username: "",
      staffId: "",
      lastLanguage: "",
      permitted: false,
      permissions: [],
    };
  },
  actions: {
    setToken(token: Token): void {
      this.accessToken = token.access_token;
      if (token.refresh_token) this.refreshToken = token.refresh_token;

      if (token.access_token) {
        const decodedAccessToken: AccessToken = jwt_decode(token.access_token);
        this.expirationTime = decodedAccessToken.exp;
        this.username = decodedAccessToken.username;
        this.staffId = decodedAccessToken.staff_id;
        this.permitted = decodedAccessToken.permitted;
        this.lastLanguage = decodedAccessToken.last_language;
        this.permissions = decodedAccessToken.permissions as PermissionString[];
      } else {
        this.expirationTime = 0;
        this.username = "";
        this.staffId = "";
        this.permitted = false;
        this.permissions = [];
      }
    },
    clearPermissions(): void {
      this.permissions = [];
    },

    clearToken(): void {
      this.accessToken = "";
      this.refreshToken = "";
      this.expirationTime = 0;
      this.username = "";
      this.staffId = "";
      this.permitted = false;
      this.permissions = [];
    },
  },
  persist: true,
});
